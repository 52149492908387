import styled from "styled-components";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { IoIosArrowDown } from "react-icons/io";

const Container = styled.div`
    width: 65vw;
    margin: 40px 0px 25px 0px;

    h3 {
        font-family: 'DM Serif Text', serif;
        font-size: 1rem;
        margin: 0px;
        margin: 10px 0px;
    }

    p {
        font-size: 0.85rem;
        line-height: 110%;

        a {
            color: var(--textColor);
            font-weight: 600;
        }
    }

    @media only screen and (max-width: 500px) {
        margin-top: 100px;
    }

    @media only screen and (max-width: 475px) {
        margin-top: 50px;
    }

    @media only screen and (max-width: 425px) {
        margin-top: 0px;
    }
`;

const SourcesContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 2rem;
    margin: 10px 0px;

    p {
        box-sizing: border-box;
        font-size: 0.85rem;
        line-height: 110%;
    }
`;

export default function Disclaimer() {
    return (
        <Container>
            <Accordion
                sx={{boxShadow: 'none'}}
            >
                <AccordionSummary
                    expandIcon={<IoIosArrowDown />}
                    aria-controls="disclaimer-panel"
                    id="disclaimer-header"
                    sx={{padding: '0px'}}
                >
                    <h3>Disclaimer</h3>
                </AccordionSummary>
                <AccordionDetails sx={{padding: '0px'}}>
                    <p>This project has been generated by scraping and cleaning data from various sources including:</p>
                    <ol>
                        <p>1) The Election Commission website (years 2008, 2013 and 2018). This data was scraped immediately after the 2018 elections.</p>
                        <p>2) <a href="http://electionpakistani.com/" target="_blank" rel="noreferrer">electionpakistani.com</a> (constituency level results for elections between 1970 and 2002)</p>
                        <p>3) A scanned copy of The Pakistan Election Compendium (Vol I), Church World Services was used to collect results for East Pakistan constituencies in 1970</p>
                    </ol>
                    <p>We would like to mention that the reader may find inaccuracies and occasional inconsistencies in the data. We have done our best to identify the most glaring ones and manually cleaning to the extent possible. Regardless, we think that tool will have value for readers despite limitations. We are happy to receive any feedback or support to improve this data.</p>
                </AccordionDetails>
            </Accordion>
            <h3>Acknowledgement</h3>
            <p>We would like to thank the folks at <a href="http://electionpakistani.com/" target="_blank" rel="noreferrer">electionpakistani.com</a> who took the pains of collating historical election data and making it publicly available. We would also like to mention <a href="https://www.prcweb.co.uk/" target="_blank" rel="noreferrer">Peter Cook’s</a> independent work on the UK’s elections that inspired the layout designs for this application.</p>
            <h3>Support</h3>
            <p>We have chosen to be ad/sponsorship free, please <a href="https://www.buymeacoffee.com/plotree" target="_blank" rel="noreferrer">support us in building similar projects</a> in the future.</p>
        </Container>
    );
}